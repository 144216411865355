import { Controller } from '@hotwired/stimulus';
import { toggle, hide } from '../utils/appearance';

export default class SelectController extends Controller {
  static targets = ['hiddenSelect', 'menu', 'optionsList', 'selected', 'button'];

  static classes = ['selected'];

  initialize() {
    const selectedOption = this.hiddenSelectTarget.querySelector('option:checked');
    this.selectedIndex = Array.from(this.hiddenSelectTarget.options).indexOf(selectedOption) || -1;
    this.icon = this.buttonTarget.querySelector('svg');
  }

  toggle(event) {
    event.preventDefault();
    toggle(this.menuTarget);
    this.toggleIconColor();
  }

  hide(event) {
    if (!this.element.contains(event.target)) {
      hide(this.menuTarget);
      this.revertIconColorToDefault();
    }
  }

  toggleIconColor() {
    if (this.icon.classList.contains('text-slate-500')) {
      this.icon.classList.remove('text-slate-500');
      this.icon.classList.add('text-slate-600');
    } else {
      this.revertIconColorToDefault();
    }
  }

  revertIconColorToDefault() {
    if (this.icon.classList.contains('text-slate-600')) {
      this.icon.classList.remove('text-slate-600');
      this.icon.classList.add('text-slate-500');
    }
  }

  select({ target }) {
    this.hiddenSelectTarget.value = target.dataset.value;
    this.selectedTarget.innerHTML = target.innerHTML;

    this.deselectAll(this.optionsListTarget);

    if (target.dataset.value !== '') {
      this.selectedClasses.forEach((selectedClass) => {
        target.classList.add(selectedClass);
      });

      this.selectedIndex = Array.from(this.optionsListTarget.children).indexOf(target);
    } else {
      this.selectedIndex = '';
    }

    this.hiddenSelectTarget.dispatchEvent(new Event('change', { bubbles: true }));
    hide(this.menuTarget);
    this.revertIconColorToDefault();
  }

  deselectAll(optionsListTarget) {
    const selectedClasses = this.selectedClasses.join(' ');
    const [selected] = optionsListTarget.getElementsByClassName(selectedClasses);
    if (!selected) return;
    this.selectedClasses.forEach((selectedClass) => {
      selected.classList.remove(selectedClass);
    });
  }

  handleKeydown(event) {
    switch (event.key) {
      case 'Space':
        toggle(this.menuTarget);
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.navigate(-1);
        break;
      case 'ArrowDown':
        event.preventDefault();
        this.navigate(1);
        break;
      case 'Escape':
        hide(this.menuTarget);
        this.revertIconColorToDefault();
        break;
      case 'Enter':
        event.preventDefault();
        this.selectOrSubmit();
        break;
      default:
        break;
    }
  }

  navigate(direction) {
    if (this.menuTarget.classList.contains('hidden')) {
      toggle(this.menuTarget);
      return;
    }

    const options = Array.from(this.optionsListTarget.children);
    if (options.length === 0) return;

    this.selectedIndex += direction;
    if (this.selectedIndex < 0) {
      this.selectedIndex = 0;
    } else if (this.selectedIndex >= options.length) {
      this.selectedIndex = options.length - 1;
    }

    options.forEach((option, index) => {
      if (index === this.selectedIndex) {
        option.classList.add(...this.selectedClasses);
        option.scrollIntoView({ block: 'nearest' });
      } else {
        option.classList.remove(...this.selectedClasses);
      }
    });
  }

  selectOrSubmit() {
    if (this.menuTarget.classList.contains('hidden')) {
      this.submitForm();
    } else {
      this.selectOption();
    }
  }

  selectOption() {
    const options = Array.from(this.optionsListTarget.children);
    if (options[this.selectedIndex]) {
      this.select({ target: options[this.selectedIndex] });
    }
    hide(this.menuTarget);
    this.revertIconColorToDefault();
  }

  submitForm() {
    const form = this.element.closest('form');
    if (form) {
      form.requestSubmit();
    }
  }
}
