import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static classes = ['selected', 'deselected'];

  static outlets = ['schedule-frequency'];

  chosenDays = new Set();

  connect() {
    const frequencyValueInput = document.getElementById('modal_every');
    if (frequencyValueInput) {
      frequencyValueInput.addEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    const intervalSelect = document.getElementById('modal_interval');
    if (intervalSelect) {
      intervalSelect.addEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    this.selectDefaultDay();
  }

  disconnect() {
    const frequencyValueInput = document.getElementById('modal_every');
    if (frequencyValueInput) {
      frequencyValueInput.removeEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    const intervalSelect = document.getElementById('modal_interval');
    if (intervalSelect) {
      intervalSelect.removeEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }
  }

  scheduleFrequencyOutletConnected(_, element) {
    element.addEventListener('schedule-frequency:scheduleChanged', this.setValuesAndSubmitForm.bind(this));
  }

  scheduleFrequencyOutletDisconnected(_, element) {
    element.removeEventListener('schedule-frequency:scheduleChanged', this.setValuesAndSubmitForm.bind(this));
  }

  toggleDay(event) {
    const day = event.target.dataset.scheduleWeeklyByDayParam;
    if (this.chosenDays.has(day)) {
      this.deselectDay(event, day);
    } else {
      this.selectDay(event, day);
    }
  }

  selectDay(event, day) {
    const { target } = event;
    this.chosenDays.add(day);

    target.classList.remove(...this.deselectedClasses);
    target.classList.add(...this.selectedClasses);
    this.setValuesAndSubmitForm();
  }

  deselectDay(event, day) {
    const { target } = event;
    this.chosenDays.delete(day);

    target.classList.remove(...this.selectedClasses);
    target.classList.add(...this.deselectedClasses);
    this.setValuesAndSubmitForm();
  }

  setValuesAndSubmitForm() {
    const scheduleSummaryForm = document.getElementById('schedule_summary_form');
    const frequencyValueInput = document.getElementById('modal_every');
    const endRepeatInput = document.getElementById('modal_end_repeat');
    const everyDataInput = document.getElementById('schedule_summary_every');
    const intervalDataInput = document.getElementById('schedule_summary_interval');
    const scheduleDataInput = document.getElementById('schedule_summary_schedule');
    const scheduleForceDateInput = document.getElementById('schedule_summary_force_date');
    const scheduleEndRepeatInput = document.getElementById('schedule_summary_end_repeat');
    const weeklyIntervalElement = document.querySelector('[data-schedule-frequency-target="weeklyInterval"]');

    const isWeeklyByDayVisible = weeklyIntervalElement && !weeklyIntervalElement.classList.contains('hidden');
    if (scheduleSummaryForm && scheduleDataInput && isWeeklyByDayVisible && endRepeatInput) {
      everyDataInput.value = frequencyValueInput.value;
      intervalDataInput.value = 'weekly';
      scheduleDataInput.value = Array.from(this.chosenDays).join(',');
      scheduleForceDateInput.value = 'true';
      scheduleEndRepeatInput.value = endRepeatInput.value;
      scheduleSummaryForm.requestSubmit();
    }
  }

  selectDefaultDay() {
    const preSelectedDay = document.getElementsByClassName('preselected-value')[0];
    if (preSelectedDay) {
      const day = preSelectedDay.getAttribute('data-schedule-weekly-by-day-param');
      this.chosenDays.add(day);
      this.setValuesAndSubmitForm();
    }
  }
}
