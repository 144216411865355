import { Controller } from '@hotwired/stimulus';

export default class TabsController extends Controller {
  static targets = ['fields', 'selectedField', 'tab'];

  static classes = ['selected'];

  get selectedTabName() {
    return this.#getSelectedTab().name;
  }

  focusNextTab() {
    this.#focusTab((currentIndex) => this.tabTargets[currentIndex + 1] || this.tabTargets[0]);
  }

  focusPreviousTab() {
    this.#focusTab((currentIndex) => this.tabTargets.at(currentIndex - 1));
  }

  #focusTab(callbackFn) {
    const [index, currentTab] = this.tabTargets.entries().find(([_, tab]) => tab.getAttribute('tabindex') === '0');
    currentTab.setAttribute('tabindex', '-1');

    const nextTab = callbackFn(index);
    nextTab.setAttribute('tabindex', '0');
    nextTab.focus();
  }

  select({ target }) {
    if (target.getAttribute('aria-selected') === 'true') return;

    const currentTab = this.#getSelectedTab();
    currentTab.setAttribute('aria-selected', 'false');
    currentTab.setAttribute('tabindex', '-1');
    currentTab.classList.remove(...this.selectedClasses);

    target.setAttribute('aria-selected', 'true');
    target.setAttribute('tabindex', '0');
    target.classList.add(...this.selectedClasses);

    const activePanel = document.getElementById(currentTab.getAttribute('aria-controls'));
    activePanel.setAttribute('hidden', 'hidden');

    const targetPanel = document.getElementById(target.getAttribute('aria-controls'));
    targetPanel.removeAttribute('hidden');

    if (this.hasSelectedFieldTarget) {
      this.selectedFieldTarget.value = target.name;
    }

    this.fieldsTargets.forEach((fieldset) => {
      if (targetPanel.contains(fieldset)) {
        fieldset.removeAttribute('disabled');
      } else {
        fieldset.setAttribute('disabled', 'disabled');
      }
    });

    target.dispatchEvent(new Event('tab:select', { bubbles: true }));
  }

  #getSelectedTab() {
    return this.tabTargets.find((tab) => tab.getAttribute('aria-selected') === 'true');
  }
}
