import { Controller } from '@hotwired/stimulus';

export default class MyWorkController extends Controller {
  static targets = ['completeButton'];

  toggleCompleted() {
    document.addEventListener('turbo:frame-load', () => {
      this.completeButtonTargets.forEach((button) => {
        button.removeAttribute('disabled');
      });
    });

    this.completeButtonTargets.forEach((button) => {
      button.setAttribute('disabled', 'disabled');
    });
  }
}
