// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '@rails/actiontext';

import Rails from '@rails/ujs';
import './channels';
import { Turbo } from '@hotwired/turbo-rails';
import './controllers';

import 'tailwindcss/tailwind.css';
import '../assets/stylesheets/pagy_customization.css';
import 'tributejs/tribute.css';
import 'trix/dist/trix.css';

Turbo.config.forms.confirm = (messageParam) => {
  let dialog;
  const idAndMessageSeparator = ':::';

  if (messageParam.includes(idAndMessageSeparator)) {
    const [id, message] = messageParam.split(idAndMessageSeparator);

    dialog = document.getElementById(`${id}-turbo-confirm`);
    dialog.querySelector('p').innerHTML = message;
  } else {
    dialog = document.getElementById('turbo-confirm');
    dialog.querySelector('p').innerHTML = messageParam;
  }

  dialog.showModal();

  return new Promise((resolve, _reject) => {
    dialog.addEventListener('close', () => {
      resolve(dialog.returnValue === 'confirm');
    }, { once: true });
  });
};

Rails.start();
