import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

import IsoDate from '../utils/iso_date';

// All dates are local, not UTC.
export default class UIDatePickerController extends Controller {
  static targets = [
    'input',
    'hidden',
    'toggle',
    'calendar',
    'month',
    'year',
    'prevMonth',
    'today',
    'nextMonth',
    'days',
  ];

  static values = {
    date: String,
    month: String,
    year: String,
    min: String,
    max: String,
    isCalendarOpen: { type: Boolean, default: false },
    isSelectOpen: { type: Boolean, default: false },
    format: { type: String, default: '%m/%d/%Y' },
    firstDayOfWeek: { type: Number, default: 0 },
    dayNameLength: { type: Number, default: 2 },
    allowWeekends: { type: Boolean, default: true },
    monthJump: { type: String, default: 'dayOfMonth' },
    disallow: Array,
    text: Object,
    locales: { type: Array, default: ['default'] },
    inheritedAction: String,
    dataProperties: String,
  };

  static defaultTextValue = {
    underflow: '',
    overflow: '',
    previousMonth: 'Previous month',
    nextMonth: 'Next month',
    today: 'Today',
    chooseDate: 'Choose Date',
    changeDate: 'Change Date',
  };

  text(key) {
    return { ...this.constructor.defaultTextValue, ...this.textValue }[key];
  }

  connect() {
    useClickOutside(this);
    if (!this.hasHiddenTarget) this.addHiddenInput();
    this.addInputAction();
    this.addToggleAction();
    this.setToggleAriaLabel();
    this.dateValue = this.validate(this.inputTarget.textContent)
      ? ''
      : this.inputTarget.textContent;
  }

  disconnect() {
    this.isCalendarOpenValue = false;
  }

  dateValueChanged(value, _previousValue) {
    if (!this.hasHiddenTarget) return;
    const dispatchChangeEvent = value !== this.hiddenTarget.value;
    this.hiddenTarget.value = value;
    this.inputTarget.textContent = value ? this.format(value) : 'mm/dd/yyyy';
    if (value) {
      this.inputTarget.classList.remove('text-slate-500');
      this.inputTarget.classList.add('text-slate-900');
    } else {
      this.inputTarget.classList.remove('text-slate-900');
      this.inputTarget.classList.add('text-slate-500');
    }
    // Trigger change event on input when user selects date from picker.
    // http://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/change_event
    if (dispatchChangeEvent) this.inputTarget.dispatchEvent(new Event('change'));
    if (dispatchChangeEvent) this.hiddenTarget.dispatchEvent(new Event('change'));
    this.validate(value);
  }

  validate(dateStr) {
    this.validationMessage(dateStr);
  }

  validationMessage(dateStr) {
    if (!dateStr) return '';
    const isoDate = new IsoDate(dateStr);
    if (this.rangeUnderflow(isoDate)) {
      return this.underflowMessage();
    } if (this.rangeOverflow(isoDate)) {
      return this.overflowMessage();
    }
    return '';
  }

  underflowMessage() {
    return this.text('underflow').replace('%s', this.format(this.minValue));
  }

  overflowMessage() {
    return this.text('overflow').replace('%s', this.format(this.maxValue));
  }

  addHiddenInput() {
    const dataPropertiesString = Object.entries(JSON.parse(this.dataPropertiesValue) || {})
      .map(([key, value]) => `data-${key}="${value}"`).join(' ');

    this.inputTarget.insertAdjacentHTML(
      'afterend',
      `
      <input type="hidden"
            name="${this.inputTarget.getAttribute('data-name') || ''}"
            id="${this.inputTarget.getAttribute('data-id') || ''}"
            value="${this.inputTarget.textContent}"
            data-action="${this.inheritedActionValue}"
            ${dataPropertiesString}
            data-datepicker-target="hidden"/>
    `,
    );
  }

  addInputAction() {
    UIDatePickerController.addAction(this.inputTarget, 'datepicker#update');
  }

  addToggleAction() {
    if (!this.hasToggleTarget) return;

    let action = 'click->datepicker#toggle';
    if (!(this.toggleTarget instanceof HTMLButtonElement)) action += ' keydown->datepicker#toggle';

    UIDatePickerController.addAction(this.toggleTarget, action);
  }

  static addAction(element, action) {
    const elementCopy = element;
    if ('action' in element.dataset) {
      elementCopy.dataset.action += ` ${action}`;
    } else {
      elementCopy.dataset.action = action;
    }
  }

  setToggleAriaLabel(value = this.text('chooseDate')) {
    if (!this.hasToggleTarget) return;
    this.toggleTarget.setAttribute('aria-label', value);
  }

  update() {
    const dateStr = this.parse(this.inputTarget.value);
    if (dateStr !== '') this.dateValue = dateStr;
  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'keydown' && ![' ', 'Enter'].includes(event.key)) return;
    if (this.hasCalendarTarget) {
      this.close(true);
    } else {
      this.open(true);
    }
  }

  close(animate) {
    if (animate) {
      this.calendarTarget.classList.add('fade-out');
      if (UIDatePickerController.hasCssAnimation(this.calendarTarget)) {
        this.calendarTarget.onanimationend = (e) => e.target.remove();
      } else {
        this.calendarTarget.remove();
      }
    } else {
      this.calendarTarget.remove();
    }
    this.isCalendarOpenValue = false;
    this.toggleTarget.focus();
  }

  open(animate, isoDate = this.initialIsoDate()) {
    this.isCalendarOpenValue = true;
    this.render(isoDate, animate);
    this.focusDate(isoDate);
  }

  // Returns the date to focus on initially.  This is `dateValue` if given
  // or today.  Whichever is used, it is clamped to `minValue` and/or `maxValue`
  // dates if given.
  initialIsoDate() {
    return this.clamp(new IsoDate(this.dateValue));
  }

  clamp(isoDate) {
    if (this.rangeUnderflow(isoDate)) {
      return new IsoDate(this.minValue);
    }
    if (this.rangeOverflow(isoDate)) {
      return new IsoDate(this.maxValue);
    }
    return isoDate;
  }

  rangeUnderflow(isoDate) {
    return this.hasMinValue && isoDate.before(new IsoDate(this.minValue));
  }

  rangeOverflow(isoDate) {
    return this.hasMaxValue && isoDate.after(new IsoDate(this.maxValue));
  }

  isOutOfRange(isoDate) {
    return this.rangeUnderflow(isoDate) || this.rangeOverflow(isoDate);
  }

  clickOutside(event) {
    if (this.isCalendarOpenValue) event.preventDefault();
    if (!this.isCalendarOpenValue) return;
    if (event.target.closest('[data-datepicker-target="calendar"]')) return;
    if (this.isSelectOpenValue) return;
    this.close(true);
  }

  // To track option is clicked
  clickOption() {
    this.isSelectOpenValue = true;
  }

  monthSelect(event) {
    this.monthValue = event.target.textContent;
    this.redraw();
    setTimeout(() => {
      this.isSelectOpenValue = false;
    }, 500);
  }

  yearSelect(event) {
    this.yearValue = event.target.textContent;
    this.redraw();
    setTimeout(() => {
      this.isSelectOpenValue = false;
    }, 500);
  }

  redraw() {
    const isoDate = this.dateFromMonthYearSelectsAndDayGrid();
    this.close(false);
    this.open(false, isoDate);
  }

  gotoPrevMonth() {
    const isoDate = this.dateFromMonthYearSelectsAndDayGrid();
    const previousMonthDate = isoDate.previousMonth(this.monthJumpValue === 'dayOfMonth');
    this.monthValue = previousMonthDate.mm;
    this.yearValue = previousMonthDate.yyyy;
    this.close(false);
    this.open(false, previousMonthDate);
    this.prevMonthTarget.focus();
  }

  gotoNextMonth() {
    const isoDate = this.dateFromMonthYearSelectsAndDayGrid();
    const nextMonthDate = isoDate.nextMonth(this.monthJumpValue === 'dayOfMonth');
    this.monthValue = nextMonthDate.mm;
    this.yearValue = nextMonthDate.yyyy;
    this.close(false);
    this.open(false, nextMonthDate);
    this.nextMonthTarget.focus();
  }

  gotoToday() {
    this.close(false);
    this.open(false, new IsoDate());
    this.todayTarget.focus();
  }

  // Returns a date where the month and year come from the dropdowns
  // and the day of the month from the grid.
  // @return [IsoDate]
  dateFromMonthYearSelectsAndDayGrid() {
    const isoDate = this.initialIsoDate();
    this.yearValue ||= isoDate.yyyy;
    this.monthValue ||= isoDate.mm;

    let day = this.daysTarget.querySelector('button[tabindex="0"] time').textContent;

    const daysInMonth = IsoDate.daysInMonth(+this.monthValue, +this.yearValue);
    if (day > daysInMonth) day = daysInMonth;

    return new IsoDate(this.yearValue, this.monthValue, day);
  }

  // Generates the HTML for the calendar and inserts it into the DOM.
  //
  // Does not focus the given date.
  //
  // @param isoDate [IsoDate] the date of interest
  render(isoDate, _animate) {
    const calendarLabel = this.text('chooseDate');
    const prevMonthLabel = this.text('previousMonth');
    const nextMonthLabel = this.text('nextMonth');

    const inputRect = this.inputTarget.getBoundingClientRect();
    let container = this.inputTarget.parentElement;
    let containerWidth;
    while (container.parentElement && !container.parentElement.className.includes('max-w')) {
      container = container.parentElement;
      containerWidth = container.getBoundingClientRect().right;
    }
    if (!container) {
      container = window;
      containerWidth = window.innerWidth;
    }

    const spaceBelow = window.innerHeight - inputRect.bottom;
    const spaceAbove = inputRect.top;
    let top; let left;

    if (spaceBelow < 307 && spaceAbove > spaceBelow) {
      top = '-top-[309px]';
    } else {
      const elementBottom = this.element.getBoundingClientRect().bottom;
      top = `top-${elementBottom}px`;
    }

    const spaceRight = containerWidth - inputRect.left;

    if (spaceRight < 252) {
      left = 'right-0';
    } else {
      left = 'left-0';
    }

    const cal = `
      <div 
        class="absolute z-10 p-3 rounded-md border border-slate-300 bg-white drop-shadow-lg h-[307px] ${top} ${left}" 
        data-datepicker-target="calendar" 
        data-action="keydown->datepicker#key" 
        role="dialog" 
        aria-modal="true" 
        aria-label="${calendarLabel}"
      >
        <div class="flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 overscroll-contain min-w-[252px]">
          <div class="flex flex-col flex-grow">
            <div class="flex h-7 justify-between relative items-center">
              <div class="flex items-center">
                <button 
                  data-datepicker-target="prevMonth" 
                  data-action="datepicker#gotoPrevMonth" 
                  title="${prevMonthLabel}" 
                  aria-label="${prevMonthLabel}"
                  class="inline-flex text-slate-900 items-center justify-center whitespace-nowrap rounded-md 
                  text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none 
                  focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 
                  disabled:pointer-events-none disabled:opacity-50 border border-slate-300 hover:bg-brand-300 
                  hover:text-brand-700 h-7 w-7 bg-white p-0 left-1" 
                  type="button"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" 
                    stroke-linejoin="round" class="lucide lucide-chevron-left h-4 w-4"
                  >
                    <path d="m15 18-6-6 6-6"></path>
                  </svg>
                </button>
              </div>
              <div class="flex h-full items-center px-6 bg-slate-100 text-sm rounded-md whitespace-nowrap">
                ${isoDate.getMonthName()}
                ${isoDate.yyyy}
              </div>
              <div class="flex items-center">
                <button 
                  data-datepicker-target="nextMonth" 
                  data-action="datepicker#gotoNextMonth" 
                  title="${nextMonthLabel}" 
                  aria-label="${nextMonthLabel}"
                  class="inline-flex text-slate-900 items-center justify-center whitespace-nowrap rounded-md 
                  text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none 
                  focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 
                  disabled:pointer-events-none disabled:opacity-50 border border-slate-300 hover:bg-brand-300 
                  hover:text-brand-700 h-7 w-7 bg-white p-0 right-1" 
                  type="button"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" 
                    stroke-linejoin="round" class="lucide lucide-chevron-right h-4 w-4"
                  >
                    <path d="m9 18 6-6-6-6"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div class="sdp-days-of-week grid grid-cols-7 text-center mt-3">
              ${this.daysOfWeek()}
            </div>
            <div 
              class="sdp-days grid grid-cols-7 text-center mt-3 h-[210px]" 
              role="grid" 
              data-datepicker-target="days" 
              data-action="click->datepicker#pick"
            >
              ${this.days(isoDate)}
            </div>
          </div>
        </div>
      </div>`;
    this.element.insertAdjacentHTML('beforeend', cal);
  }

  pick(event) {
    event.preventDefault();

    let button; let
      time;
    switch (event.target.constructor) {
      case HTMLTimeElement:
        time = event.target;
        button = time.parentElement;
        break;
      case HTMLButtonElement:
        button = event.target;
        [time] = button.children;
        break;
      default:
        return;
    }

    if (button.hasAttribute('aria-disabled')) return;
    const dateStr = time.getAttribute('datetime');
    this.selectDate(new IsoDate(dateStr));
  }

  key(event) {
    switch (event.key) {
      case 'Escape':
        this.close(true);
        return;
      case 'Tab':
        if (event.shiftKey) {
          if (document.activeElement === this.firstTabStop()) {
            event.preventDefault();
            this.lastTabStop().focus();
          }
        } else if (document.activeElement === this.lastTabStop()) {
          event.preventDefault();
          this.firstTabStop().focus();
        }
        return;
      default:
        break;
    }

    const button = event.target;
    if (!this.daysTarget.contains(button)) return;

    const dateStr = button.children[0].getAttribute('datetime');
    const isoDate = new IsoDate(dateStr);

    switch (event.key) {
      case 'Enter':
      case ' ':
        event.preventDefault();
        if (!button.hasAttribute('aria-disabled')) this.selectDate(isoDate);
        break;
      case 'ArrowUp':
      case 'k':
        this.focusDate(isoDate.previousWeek());
        break;
      case 'ArrowDown':
      case 'j':
        this.focusDate(isoDate.nextWeek());
        break;
      case 'ArrowLeft':
      case 'h':
        this.focusDate(isoDate.previousDay());
        break;
      case 'ArrowRight':
      case 'l':
        this.focusDate(isoDate.nextDay());
        break;
      case 'Home':
      case '0':
      case '^':
        this.focusDate(isoDate.firstDayOfWeek(this.firstDayOfWeekValue));
        break;
      case 'End':
      case '$':
        this.focusDate(isoDate.lastDayOfWeek(this.firstDayOfWeekValue));
        break;
      case 'PageUp':
        if (event.shiftKey) {
          this.focusDate(isoDate.previousYear());
        } else {
          this.focusDate(isoDate.previousMonth(this.monthJumpIsDayOfMonth()));
        }
        break;
      case 'PageDown':
        if (event.shiftKey) {
          this.focusDate(isoDate.nextYear());
        } else {
          this.focusDate(isoDate.nextMonth(this.monthJumpIsDayOfMonth()));
        }
        break;
      case 'b':
        this.focusDate(isoDate.previousMonth(this.monthJumpIsDayOfMonth()));
        break;
      case 'B':
        this.focusDate(isoDate.previousYear());
        break;
      case 'w':
        this.focusDate(isoDate.nextMonth(this.monthJumpIsDayOfMonth()));
        break;
      case 'W':
        this.focusDate(isoDate.nextYear());
        break;
      default:
        break;
    }
  }

  firstTabStop() {
    return this.prevMonthTarget;
  }

  lastTabStop() {
    return this.todayTarget;
  }

  monthJumpIsDayOfMonth() {
    return this.monthJumpValue === 'dayOfMonth';
  }

  // @param isoDate [isoDate] the date to select
  selectDate(isoDate) {
    if (this.isSelectOpenValue) return;

    this.close(true);
    this.toggleTarget.focus();
    this.dateValue = isoDate.toString();
  }

  // Focuses the given date in the calendar.
  // If the date is not visible because it is in the hidden part of the previous or
  // next month, the calendar is updated to show the corresponding month.
  //
  // @param isoDate [IsoDate] the date to focus on in the calendar
  focusDate(isoDate) {
    const time = this.daysTarget.querySelectorAll(`time[datetime="${isoDate.toString()}"]`)[0];

    if (!time) {
      const leadingDatetime = this.daysTarget.querySelectorAll('time')[0].getAttribute('datetime');
      if (isoDate.before(new IsoDate(leadingDatetime))) {
        this.gotoPrevMonth();
      } else {
        this.gotoNextMonth();
      }
      this.focusDate(isoDate);
      return;
    }

    const currentFocus = this.daysTarget.querySelectorAll('button[tabindex="0"]')[0];
    if (currentFocus) currentFocus.setAttribute('tabindex', -1);

    const button = time.parentElement;
    button.setAttribute('tabindex', 0);
    button.focus();

    if (!button.hasAttribute('aria-disabled')) {
      this.setToggleAriaLabel(`${this.text('changeDate')}, ${this.format(isoDate.toString())}`);
    }
  }

  // @param selected [Number] the selected month (January is 1)
  monthOptions(selected) {
    const klass = 'hover:bg-gray-100 cursor-pointer py-2 px-4';
    return this.monthNames('long')
      .map(
        (name, i) => `<div class="${klass}" value="${i + 1}" ${
          i + 1 === selected ? 'selected' : ''
        }>${name}</div>`,
      )
      .join('');
  }

  // @param selected [Number] the selected year
  static yearOptions(selected) {
    const years = [];
    const extent = 10;
    const klass = 'hover:bg-gray-100 cursor-pointer py-2 px-4';

    for (let y = selected - extent; y <= selected + extent; y += 1) years.push(y);
    return years
      .map(
        (year) => `<div class="${klass}" value=${year} ${year === selected ? 'selected' : ''}>${year}</div>`,
      )
      .join('');
  }

  daysOfWeek() {
    return this.dayNames('long')
      .map(
        (
          name,
        ) => `<div scope="col" class="text-slate-500 rounded-md w-9 font-normal text-[0.8rem]" aria-label="${name}">
            ${name.slice(0, this.dayNameLengthValue)} </div>`,
      )
      .join('');
  }

  // Generates the day grid for the given date's month.
  // The end of the previous month and the start of the next month
  // are shown if there is space in the grid.
  //
  // Does not focus on the given date.
  //
  // @param isoDate [IsoDate] the month of interest
  // @return [String] HTML for the day grid
  days(isoDate) {
    const days = [];
    const selected = new IsoDate(this.dateValue);
    let date = isoDate.setDayOfMonth(1).firstDayOfWeek(this.firstDayOfWeekValue);

    while (true) {
      const isPreviousMonth = date.mm !== isoDate.mm && date.before(isoDate);
      const isNextMonth = date.mm !== isoDate.mm && date.after(isoDate);

      if (isNextMonth && date.isFirstDayOfWeek(this.firstDayOfWeekValue)) break;

      const outsideMonthClass = 'day-outside !text-slate-400';
      const baseClasses = `
        sdp-day inline-flex items-center justify-center whitespace-nowrap rounded-md 
        text-sm ring-offset-background transition-colors h-9 w-9 p-0 font-normal 
        text-slate-900
      `.trim();
      const stateClasses = `focus-visible:outline-none focus-visible:ring-2 
        focus-visible:ring-ring focus-visible:ring-offset-0 
        disabled:pointer-events-none hover:bg-brand-300 
        hover:text-brand-700 aria-selected:opacity-100`.trim();

      const selectedClasses = 'sdp-selected bg-brand-600 text-white hover:bg-brand-300 hover:text-brand-700';

      const klass = UIDatePickerController.classAttribute(
        baseClasses,
        isPreviousMonth ? outsideMonthClass : '',
        isNextMonth ? outsideMonthClass : '',
        date.isToday() ? 'sdp-today' : '',
        date.isWeekend() ? 'sdp-weekend' : '',
        this.dateValue && date.equals(selected) ? selectedClasses : '',
        this.isDisabled(date) ? 'sdp-disabled !text-slate-300' : stateClasses,
      );

      days.push(`
          <button type="button"
                  tabindex="-1"
                  ${klass}
                  ${date.equals(selected) ? 'aria-selected="true"' : ''}
                  ${this.isDisabled(date) ? 'aria-disabled="true"' : ''}
          >
            <time datetime="${date.toString()}">${+date.dd}</time>
          </button>
      `);
      date = date.nextDay();
    }

    return days.join('');
  }

  static classAttribute(...classes) {
    const presentClasses = classes.filter((c) => c);
    if (presentClasses.length === 0) return '';
    return `class="${presentClasses.join(' ')}"`;
  }

  isDisabled(isoDate) {
    return (
      this.isOutOfRange(isoDate)
      || (isoDate.isWeekend() && !this.allowWeekendsValue)
      || this.disallowValue.includes(isoDate.toString())
    );
  }

  // Formats an ISO8601 date, using the `format` value, for display to the user.
  // Returns an empty string if `str` cannot be formatted.
  //
  // @param str [String] a date in YYYY-MM-DD format
  // @return [String] the date in a user-facing format, or an empty string if the
  //   given date cannot be formatted
  format(str) {
    if (!IsoDate.isValidStr(str)) return '';

    const [yyyy, mm, dd] = str.split('-');

    return this.formatValue
      .replace('%d', dd)
      .replace('%-d', +dd)
      .replace('%m', UIDatePickerController.zeroPad(mm))
      .replace('%-m', +mm)
      .replace('%B', this.localisedMonth(mm, 'long'))
      .replace('%b', this.localisedMonth(mm, 'short'))
      .replace('%Y', yyyy)
      .replace('%y', +yyyy % 100);
  }

  // Returns a two-digit zero-padded string.
  static zeroPad(num) {
    return num.toString().padStart(2, '0');
  }

  // Parses a date from the user, using the `format` value, into an ISO8601 date.
  // Returns an empty string if `str` cannot be parsed.
  //
  // @param str [String] a user-facing date, e.g. 19/03/2022
  // @return [String] the date in ISO8601 format, e.g. 2022-03-19; or an empty string
  //   if the given date cannot be parsed
  parse(str) {
    const directives = {
      d: [
        '\\d{2}',
        (match) => {
          this.day = +match;
        },
      ],
      '-d': [
        '\\d{1,2}',
        (match) => {
          this.day = +match;
        },
      ],
      m: [
        '\\d{2}',
        (match) => {
          this.month = +match;
        },
      ],
      '-m': [
        '\\d{1,2}',
        (match) => {
          this.month = +match;
        },
      ],
      B: [
        '\\w+',
        (match, controller) => {
          this.month = controller.monthNumber(match, 'long');
        },
      ],
      b: [
        '\\w{3}',
        (match, controller) => {
          this.month = controller.monthNumber(match, 'short');
        },
      ],
      Y: [
        '\\d{4}',
        (match) => {
          this.year = +match;
        },
      ],
      y: [
        '\\d{2}',
        (match) => {
          this.year = 2000 + +match;
        },
      ],
    };
    const funcs = [];
    const re = new RegExp(
      this.formatValue.replace(/%(d|-d|m|-m|B|b|Y|y)/g, (_, p) => {
        const directive = directives[p];
        funcs.push(directive[1]);
        return `(${directive[0]})`;
      }),
    );
    const matches = str.match(re);
    if (!matches) return '';

    const parts = {};
    for (let i = 0, len = funcs.length; i < len; i += 1) {
      funcs[i].call(parts, matches[i + 1], this);
    }

    if (!IsoDate.isValidDate(parts.year, parts.month, parts.day)) return '';
    return new IsoDate(parts.year, parts.month, parts.day).toString();
  }

  // Returns the name of the month in the configured locale.
  //
  // @param month [Number] the month number (January is 1)
  // @param monthFormat [String] "long" (January) | "short" (Jan)
  // @return [String] the localised month name
  localisedMonth(month, monthFormat) {
    // Use the middle of the month to avoid timezone edge cases
    return new Date(`2022-${month}-15`).toLocaleString(this.localesValue, { month: monthFormat });
  }

  // Returns the number of the month (January is 1).
  //
  // @param name [String] the name of the month in the current locale (e.g. January or Jan)
  // @param monthFormat [String] "long" (January) | "short" (Jan)
  // @return [Number] the number of the month, or 0 if name is not recognised
  monthNumber(name, monthFormat) {
    return this.monthNames(monthFormat).findIndex((m) => name.includes(m)) + 1;
  }

  // Returns the month names in the configured locale.
  //
  // @param format [String] "long" (January) | "short" (Jan)
  // @return [Array] localised month names
  monthNames(format) {
    const formatter = new Intl.DateTimeFormat(this.localesValue, { month: format });
    return ['01', '02', '03', '04', '05', '06', '07', '08', '09',
      '10', '11', '12'].map((mm) => formatter.format(new Date(`2022-${mm}-15`)));
  }

  // Returns the day names in the configured locale, starting with the
  // firstDayOfTheWeekValue.
  //
  // @param format [String] "long" (Monday) | "short" (Mon) | "narrow" (M)
  // @return [Array] localised day names
  dayNames(format) {
    const formatter = new Intl.DateTimeFormat(this.localesValue, { weekday: format });
    const names = [];
    // Ensure date in month is two digits. 2022-04-10 is a Sunday
    for (let i = this.firstDayOfWeekValue + 10, n = i + 7; i < n; i += 1) {
      names.push(formatter.format(new Date(`2022-04-${i}T00:00:00`)));
    }
    return names;
  }

  static hasCssAnimation(el) {
    return window.getComputedStyle(el).getPropertyValue('animation-name') !== 'none';
  }
}
